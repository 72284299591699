import React, { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import { EyeVisibleIcon } from "../../icons/EyeVisibleIcon";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation } from "@tanstack/react-query";
import {
  resetPassword,
  sendForgotPasswordRequest,
} from "../../api/user.service";
import { useSnackbar } from "../../hooks/snackbar";
import { blackAndWhite } from "../../styles";
import { LoadingButton } from "@mui/lab";
import { explore } from "../../routes/student.routes";
import { login } from "../../api/auth.service";
import { loginSuccessAction } from "../../reducers/auth.reducer";
import { listStudents } from "../../routes/admin.routes";
import { courseList } from "../../routes/instructor.routes";

type ILogin = {
  email: string;
  motDePasse: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Champs email obligatoire")
    .email("Email invalide"),
  motDePasse: Yup.string().required("Champs mot de passe obligatoire"),
});

const LoginForm: FC<{
  onClickOnForgetPassword: () => void;
}> = (props) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
    motDePasse: string;
  }>({
    resolver: yupResolver(validationSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const loginMutation = useMutation<any, { message: string }, ILogin>({
    mutationKey: ["login"],
    mutationFn: (data) =>
      login(data.email, data.motDePasse)
        .then(async (response) => {
          await dispatch(
            loginSuccessAction({
              data: response.data.data,
              accessToken: response.data.accessToken,
            })
          );
          return response.data;
        })
        .then((response) => {
          const role = response.data.role;
          let url;
          switch (role) {
            case "admin": {
              url = listStudents;
              break;
            }
            case "formateur": {
              url = courseList;
              break;
            }
            case "etudiant": {
              url = explore;
              break;
            }
          }

          if (!!url) {
            navigate(url, {});
            window.location.reload();
          }
        })
        .catch((e) => {
          const error = e.response.data;
          if (error.message === "invalid_credentials") {
            snackbar.show("Coordonnées invalides", "error");
          }
        }),
  });

  const onSubmit = (data: ILogin) => {
    loginMutation.mutate(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label={"Email"}
              error={errors.email?.message !== undefined}
              helperText={errors.email?.message}
              fullWidth
              {...register("email")}
              variant={"standard"}
              placeholder={"Email"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Mot de passe"}
              error={errors.motDePasse?.message !== undefined}
              helperText={errors.motDePasse?.message}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={"mr-2"}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <EyeVisibleIcon
                          color={"#121212"}
                          width={25}
                          height={25}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              {...register("motDePasse")}
              variant={"standard"}
              placeholder={"Mot de passe"}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-end"} className={"mt-3"}>
          <Button
            variant={"text"}
            children={"Mot de passe oublié?"}
            onClick={() => {
              props.onClickOnForgetPassword();
            }}
          />
        </Grid>
        <Grid mt={3}>
          <LoadingButton
            loading={loginMutation.isLoading}
            type="submit"
            fullWidth
            {...blackAndWhite}
            children={"Se Connecter"}
          />
        </Grid>
      </form>
    </div>
  );
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Champs obligatoire").email("Email invalide"),
});

interface ForgotPasswordForm {
  email: string;
}

interface ResetPasswordForm {
  code: string;
  newPassword: string;
  confirmPassword: string;
}

const resetPasswordSchema = Yup.object().shape({
  code: Yup.string()
    .required("Champs obligatoire")
    .min(5, "Doit contenir au moins 5 caractères"),
  newPassword: Yup.string()
    .required("Champs obligatoire")
    .min(5, "Doit contenir au moins 5 caractères"),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Les mots de passe doit être identiques",
    function (value) {
      return this.parent.newPassword === value;
    }
  ),
});
const ForgotPassword: FC<{
  onChangePassword: () => void;
}> = (props) => {
  const sendForgotPasswordMutation = useMutation<
    unknown,
    unknown,
    ForgotPasswordForm
  >({
    mutationKey: ["sendForgotPasswordRequest"],
    mutationFn: (values) => {
      return sendForgotPasswordRequest(values.email)
        .then(() => {
          setIsResentIsSent(true);
        })
        .catch((e) => {
          console.error(e.response.data);
          snackbar.show("Erreur du serveur", "error");
        });
    },
  });
  const snackbar = useSnackbar();
  const requestForm = useForm<ForgotPasswordForm>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const resetForm = useForm<ResetPasswordForm>({
    resolver: yupResolver(resetPasswordSchema),
  });
  const resetPasswordMutation = useMutation<
    unknown,
    unknown,
    ResetPasswordForm
  >({
    mutationKey: ["resetPassword"],
    mutationFn: (values) => {
      return resetPassword(
        requestForm.getValues("email"),
        values.code,
        values.newPassword
      )
        .then(() => {
          props.onChangePassword();
        })
        .catch((e) => {
          const error = e.response.data.message;
          if (error === "invalid_code") {
            resetForm.setError("code", {
              type: "min",
              message: "Code invalide",
            });
          }
        });
    },
  });

  const [resetIsSent, setIsResentIsSent] = useState(false);

  return (
    <div>
      {!resetIsSent && (
        <form
          onSubmit={requestForm.handleSubmit((values) => {
            sendForgotPasswordMutation.mutate(values);
          })}
        >
          <div style={{ marginBottom: "2rem" }}>
            <TextField
              label={"Email"}
              variant={"standard"}
              fullWidth={true}
              {...requestForm.register("email")}
              error={!!requestForm.formState.errors.email}
              helperText={requestForm.formState.errors.email?.message}
              placeholder={"Email"}
            />
          </div>

          <LoadingButton
            loading={sendForgotPasswordMutation.isLoading}
            type={"submit"}
            {...blackAndWhite}
            fullWidth={true}
          >
            Valider
          </LoadingButton>
        </form>
      )}
      {resetIsSent && (
        <form
          onSubmit={resetForm.handleSubmit((values) => {
            resetPasswordMutation.mutate(values);
          })}
        >
          <Box
            sx={{
              "& > *": {
                marginBottom: 2,
              },
            }}
          >
            <Alert severity={"info"}>
              Veuillez saisir le code envoyé par e-mail pour changer votre mot
              de passe
            </Alert>
            <TextField
              label={"Code"}
              {...resetForm.register("code")}
              fullWidth
              variant={"standard"}
              error={!!resetForm.formState.errors.code}
              helperText={resetForm.formState.errors.code?.message}
              placeholder={"Code"}
            />
            <TextField
              label={"Nouveau mot de passe"}
              {...resetForm.register("newPassword")}
              fullWidth
              type={"password"}
              variant={"standard"}
              autoComplete={"new-password"}
              error={!!resetForm.formState.errors.newPassword}
              helperText={resetForm.formState.errors.newPassword?.message}
              placeholder={"Nouveau mot de passe"}
            />
            <TextField
              label={"Confirmation du mot de passe"}
              {...resetForm.register("confirmPassword")}
              variant={"standard"}
              type={"password"}
              autoComplete={"new-password"}
              fullWidth
              error={!!resetForm.formState.errors.confirmPassword}
              helperText={resetForm.formState.errors.confirmPassword?.message}
              placeholder={"Confirmation du mot de passe"}
            />
            <LoadingButton
              loading={resetPasswordMutation.isLoading}
              fullWidth
              {...blackAndWhite}
              type={"submit"}
            >
              Changer le mot de passe
            </LoadingButton>
          </Box>
        </form>
      )}
    </div>
  );
};

export function LoginPage() {
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  return (
    <>
      <Grid container>
        <Grid
          item
          md={8}
          style={{
            background: "#023047 0% 0% no-repeat padding-box",
            width: "100%",
            height: "100vh",
            opacity: 0.64,
          }}
          className={"d-none d-md-block"}
        >
          <img
            src={
              "https://res.cloudinary.com/faavly/image/upload/v1676919652/Login_image_11zon_u39oyz.jpg"
            }
            style={{ width: "100%", height: "100%" }}
            alt={"logo img"}
          />
        </Grid>
        <Grid
          item
          md={4}
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
        >
          <div className={"m-5"}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={"/Civilab Logo.svg"} alt={"logo civilab"} />
            </div>
            <div>
              <Typography
                style={{
                  fontSize: "1.5rem",
                  marginTop: 12,
                  textAlign: "center",
                }}
                fontWeight={100}
              >
                A Bridge to Excellence
              </Typography>
            </div>
            <Box mt={5}>
              {!showForgetPassword && (
                <LoginForm
                  onClickOnForgetPassword={() => {
                    setShowForgetPassword(true);
                  }}
                />
              )}
              {showForgetPassword && (
                <ForgotPassword
                  onChangePassword={() => {
                    setShowForgetPassword(false);
                  }}
                />
              )}
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
